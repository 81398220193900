
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Title" },
      { field: "Category.Name" },
      { field: "Ordinal" },
      { field: "StartDate" },
      { field: "StartDate" },
      { field: "EndDate" },
      { field: "Published" },
    ];

    const gridOptions: GridOptions = {
      id: "user",
      title: "最新消息",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "最新消息清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "最新消息清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        { field: "Category.Name", title: "類別", showHeaderOverflow: true, showOverflow: true },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
        {
          field: 'StartDate', title: '開始日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        {
          field: 'EndDate', title: '結束日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        {
          field: "Published", title: "已發佈", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'StartDate', order: 1 });
          }
          return model.dispatch("news/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch("news/query") : undefined,
        save: model ? (params) => model.dispatch("news/save", params) : undefined,
      },
      modalConfig: { width: 800, height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Title", title: "標題", span: 24, itemRender: { name: "$input", props: { placeholder: "請輸入標題" } } },
        { field: "CategoryId", title: "類別", span: 24, slots: { default: "column-category-id" } },
        { field: "StartDate", title: "開始日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "EndDate", title: "結束日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Published", title: "已發佈", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
        { field: "Ordinal", title: "排序", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入數值" } } },
        // { field: "Introduction", title: "簡介", span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入簡介', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        Title: [{ required: true }],
        CategoryId: [{ required: true }],
      }
    };

    const categoryIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇分類',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("newsCategory/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("newsCategory/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          else params.condition = new Condition('ParentId', Operator.IsNull)
          return model!.dispatch("newsCategory/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: 'ParentId', children: 'Children' }
    }

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      categoryIdSelectOptions,
      editorOptions,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  }
});
