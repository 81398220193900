<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-2xl font-bold mr-auto">最新消息</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <div v-show="!grid.isEditing && grid.isRowSelected" id="batch-dropdown" class="dropdown ml-auto mr-2 sm:ml-0">
          <button class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center">
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" /> 刪除
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" /> 列印
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span> -->
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出 HTML
              </span> -->
            </div>
          </div>
        </div>
        <button v-show="!grid.isEditing" class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow">
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input type="text" class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border" v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字" @keyup.enter="grid.refresh" />
          </div>
          <div class="mt-2 xl:mt-0">
            <button type="button" class="button w-full sm:w-16 bg-theme-1 text-white" @click="grid.refresh">
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <button class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })">
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button>
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300">
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })">
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <grid ref="grid" v-bind="gridOptions"
          @addNewRow="(row, callback) => { row.Ordinal = 0; row.Published = false; row.Content = {}; callback(); }"
          @edit="(row, callback) => { row.Password = undefined; callback(); }"
          @reset="(row, callback) => { callback(); row.Online = false; row.Password = undefined; }"
          @removeSelectedRows="hideBatchDropDown()">
          <template #modal="{ row, submit, reset }">
            <div class="flex items-center">
              <vxe-form ref="form" v-bind="formOptions" :data="row" @reset="reset" @submit="submit">
                <template #column-category-id="{ data }">
                  <SelectBox :transfer="true" v-model="data.CategoryId" v-bind="categoryIdSelectOptions" />
                </template>
              </vxe-form>
            </div>
            <div v-if="row && row.Content" class="post intro-y overflow-hidden box mx-3 mt-2 border p-3">
              <CKEditor v-model="row.Content.Content" v-bind="editorOptions" />
            </div>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認"
              @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template>
          <template #filter-name="{ column, $panel }">
            <div v-for="(option, index) in column.filters" :key="index" class="flex item-center justify-center">
              <input type="checkbox" class="input input--switch border mt-4 ml-2" :checked="option.checked"
                @input="grid.changeFilterEvent(column, option, $panel, true)" />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600">
                  {{ option.label }}
                </div>
                <input v-model.trim="option.data" :disabled="!option.checked" type="text"
                  class="input pl-12 w-full border col-span-4" placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(column, option, $panel)" />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import formatDate from 'xe-utils/toDateString'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ClassicEditor from "@/global-components/ckeditor/classic-editor";
import UploadAdapterPlugin from "@/global-components/ckeditor/upload-adapter-plugin";
import "@/global-components/ckeditor/styles.css";

export default defineComponent({
  components: {
    Grid,
    SelectBox,
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [
      { field: "Title" },
      { field: "Category.Name" },
      { field: "Ordinal" },
      { field: "StartDate" },
      { field: "StartDate" },
      { field: "EndDate" },
      { field: "Published" },
    ];

    const gridOptions: GridOptions = {
      id: "user",
      title: "最新消息",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true,
      },
      printConfig: {
        sheetName: "最新消息清單",
        columns: printColumns,
        modes: ["current", "selected", "all"],
      },
      exportConfig: {
        filename: "最新消息清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns,
      },
      columns: [
        {
          field: "Title", title: "標題", showHeaderOverflow: true, showOverflow: true, sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        { field: "Category.Name", title: "類別", showHeaderOverflow: true, showOverflow: true },
        { field: 'Ordinal', title: '排序', showHeaderOverflow: true, showOverflow: true, align: "right", width: 100, sortable: true },
        {
          field: 'StartDate', title: '開始日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        {
          field: 'EndDate', title: '結束日期', showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : ""
        },
        {
          field: "Published", title: "已發佈", showHeaderOverflow: true, showOverflow: true, align: "center", width: 100, sortable: true, resizable: false,
          cellRender: { name: "$switch", props: { openLabel: '是', closeLabel: '否', disabled: true } }
        },
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'StartDate', order: 1 });
          }
          return model.dispatch("news/query", params)
        } : undefined,
        queryAll: model ? () => model.dispatch("news/query") : undefined,
        save: model ? (params) => model.dispatch("news/save", params) : undefined,
      },
      modalConfig: { width: 800, height: "80%", showFooter: true },
    };

    const formOptions: VxeFormProps = {
      titleWidth: 80,
      titleAlign: "right",
      span: 3,
      items: [
        { field: "Title", title: "標題", span: 24, itemRender: { name: "$input", props: { placeholder: "請輸入標題" } } },
        { field: "CategoryId", title: "類別", span: 24, slots: { default: "column-category-id" } },
        { field: "StartDate", title: "開始日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "EndDate", title: "結束日期", span: 12, itemRender: { name: "$input", props: { type: "date", placeholder: "請輸入日期" } } },
        { field: "Published", title: "已發佈", span: 12, itemRender: { name: '$select', options: [{ value: false, label: '否' }, { value: true, label: '是' }] } },
        { field: "Ordinal", title: "排序", span: 12, itemRender: { name: "$input", props: { type: "number", placeholder: "請輸入數值" } } },
        // { field: "Introduction", title: "簡介", span: 24, itemRender: { name: '$input', props: { placeholder: '請輸入簡介', clearable: true }, attrs: { type: 'text' } } },
      ],
      rules: {
        Title: [{ required: true }],
        CategoryId: [{ required: true }],
      }
    };

    const categoryIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: 'Id',
      placeholder: '選擇分類',
      textField: 'Name',
      valueField: 'Id',
      formatText: async (row: any) => {
        let entity = await model!.dispatch("newsCategory/find", row.Id); // eslint-disable-line
        let text = entity.Name
        while (entity.Parent) {
          entity = entity.Parent
          text = `${entity.Name}>${text}`
        }
        return text
      },
      columns: [
        { field: 'Name', title: '名稱', showHeaderOverflow: true, showOverflow: true, sortable: true, treeNode: true }
      ],
      promises: {
        find: (value) => model!.dispatch("newsCategory/find", value), // eslint-disable-line
        query: (params) => {
          if (params.condition) params.condition.and('ParentId', Operator.IsNull)
          else params.condition = new Condition('ParentId', Operator.IsNull)
          return model!.dispatch("newsCategory/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: 'ParentId', children: 'Children' }
    }

    const editorOptions = {
      editor: ClassicEditor,
      config: {
        extraPlugins: [UploadAdapterPlugin],
        toolbar: {
          shouldNotGroupWhenFull: true,
          items: [
            "heading",
            "|",
            "fontFamily",
            "fontSize",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "alignment",
            "bulletedList",
            "numberedList",
            "outdent",
            "indent",
            "highlight",
            "insertTable",
            "|",
            "link",
            "blockQuote",
            "imageInsert",
            "mediaEmbed",
            // 'codeBlock',
            "htmlEmbed",
            "|",
            "|",
            "undo",
            "redo",
            "sourceEditing"
          ]
        },
        removePlugins: ["Markdown"],
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:full",
            "imageStyle:side",
            "linkImage"
          ]
        },
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableCellProperties",
            "tableProperties"
          ]
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph"
            },
            {
              model: "heading1",
              view: { name: "h1", classes: "font-bold text-xl" },
              title: "Heading 1",
              class: "ck-heading_heading1"
            },
            {
              model: "heading2",
              view: { name: "h2", classes: "font-bold text-base" },
              title: "Heading 2",
              class: "ck-heading_heading2"
            }
          ]
        }
      }
    }

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      categoryIdSelectOptions,
      editorOptions,
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
  }
});
</script>
